import React from 'react';
import { useHistory } from 'react-router-dom';

const Product = (props) => {
    const { name, price, image } = props.product;

    const history = useHistory();

    const handleBuy = ( name, price ) => {
        const newCart = {
            productName: name,
            price: price
        }
        sessionStorage.setItem('cart', JSON.stringify( newCart ));
        history.push('/checkout');
    }

    return (
        <div className="flex justify-around flex-col rounded-md shadow-md gap-2">
            <img className="w-1/2 mx-auto mt-2" src={ image } alt=""/>
            <h2 className="text-center my-5 font-bold text-2xl">{ name }</h2>
            <div className="flex flex-row justify-between m-2">
                <h2 className="py-2 px-4 text-2xl text-blue-600 font-bold">{ price }$</h2>
                <button onClick={ () => handleBuy( name, price ) } className="bg-blue-600 py-2 px-4 text-white hover:bg-indigo-600 rounded-lg font-medium">Buy Now</button>
            </div>
        </div>
    );
};

export default Product;