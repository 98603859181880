import React, { useEffect, useState } from 'react';
import Product from '../Product/Product';
import Spinner from '../Spinner/Spinner';

const Home = () => {
    const [ products, setProducts ] = useState( [] );
    useEffect( () => {
        fetch('https://warm-castle-19351.herokuapp.com/products')
        .then( res => res.json() )
        .then( data => setProducts( data ) )
    }, [])

    return (
        <main className="container max-auto mb-16 mt-5">
            <h1 className="text-xl text-gray-700 font-bold py-5 px-2 mb-3">SmartPhones</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                {
                    products.length > 0 ? products.map( product => <Product product={ product } key={ product._id }></Product> ) : <Spinner></Spinner>
                }
            </div>
        </main>
    );
};

export default Home;