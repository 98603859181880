import React, { useContext } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { UserContext } from '../../App';
import { useHistory, useLocation } from 'react-router';
import firebaseConfig from './FirebaseConfig';

const Login = () => {
    const history = useHistory();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const [ user, setUser ] = useContext(UserContext);
    
    if (!firebase.apps.length) {
        firebase.initializeApp( firebaseConfig );
    }
    const authGoogle = new firebase.auth.GoogleAuthProvider();

    const handleLogin = () => {
        firebase.auth()
            .signInWithPopup( authGoogle )
            .then((result) => {
                const { displayName, email, photoURL } = result.user;
                const newUser = {
                    name: displayName,
                    email: email,
                    image: photoURL,
                    loggedIn: true
                }
                setUser( newUser );
                history.replace( from )
            }).catch(( error ) => {
                console.log( error.message );
            });
    }

    return (
        <main className="container max-auto my-8 text-center">
            <button onClick={ () => handleLogin() } className="py-3 px-8 bg-blue-50 text-gray-600 hover:bg-blue-100 rounded-2xl border-gray-100"><FontAwesomeIcon icon={ faGoogle } /> Continue with Google</button>
        </main>
    );
};

export default Login;