import React from 'react';
import './Spinner.css';

const Spinner = () => {
    return (
        <div className="p-3 mx-auto">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
        </div>
    );
};

export default Spinner;