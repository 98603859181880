import React from 'react';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Product = ( props ) => {
    const { _id, name, price } = props.product;
    const setStatusMessage = props.setStatusMessage;

    const productDeleteSuccess = () => {
        return <div onClick={ () => setStatusMessage('') } className="bg-green-50 p-3 mt-5 border-solid border-2 border-green-100 rounded-lg text-center text-green-600">
            <FontAwesomeIcon icon={ faCheck } /> Product deleted successfully!
        </div>
    }
    const productDeleteError = () => {
        return <div onClick={ () => setStatusMessage('') } className="bg-red-50 p-3 mt-5 border-solid border-2 border-red-100 rounded-lg text-center text-red-600">
            <FontAwesomeIcon icon={ faTimes } /> Error while deleting product.
        </div>
    }
    
    const handleDelete = ( id ) => {
        fetch('https://warm-castle-19351.herokuapp.com/product', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
        .then(res => res.json())
        .then(res => {
            res ? setStatusMessage(productDeleteSuccess) : setStatusMessage(productDeleteError)
        })
    }

    return (
        <div className="grid grid-cols-4 bg-gray-50 border border-gray-100 text-gray-600 p-3 font-medium">
            <div className="col-span-2">
                { name }
            </div>
            <div className="text-center">
                { price }$
            </div>
            <div className="text-center text-red-700">
                <span className="cursor-pointer" onClick={ () => handleDelete( _id ) }>
                    <FontAwesomeIcon icon={ faTrash } />
                </span>
            </div>
        </div>
    );
};

export default Product;