import React, { useEffect, useState, useContext } from 'react';
import Order from '../Order/Order';
import Spinner from '../Spinner/Spinner';
import { UserContext } from '../../App';

const Orders = () => {
    const [ user, setUser ] = useContext(UserContext);
    const [ orders, setOrder ] = useState([]);
    useEffect(() => {
        fetch('https://warm-castle-19351.herokuapp.com/orders?email='+user.email)
        .then( res => res.json() )
        .then( data => setOrder( data ) )
    }, [])
    return (
        <main className="container max-auto">
            <h1 className="text-2xl text-gray-700 font-bold py-5 px-2">Orders</h1>
            <div className="grid grid-cols shadow-lg rounded-lg">
                <div className="grid grid-cols-5 bg-gray-100 text-gray-700 p-3 font-bold">
                    <div className="col-span-2">
                        Name
                    </div>
                    <div className="text-center">
                        Quantity
                    </div>
                    <div className="text-center">
                        Price
                    </div>
                    <div className="text-center">
                        Date
                    </div>
                </div>
                <div className="border border-gray-100"></div>
                {
                    orders.length > 0 ? orders.map( order => <Order order={ order } key={ order._id }></Order> ) : <Spinner></Spinner>
                }
            </div>
        </main>
    );
};

export default Orders;