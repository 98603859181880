import React from 'react';

const Order = ( props ) => {
    const { product, price, quantity, date } = props.order;
    return (
        <div className="grid grid-cols-5 bg-gray-50 border border-gray-100 text-gray-600 p-3 font-medium">
            <div className="col-span-2">
                { product }
            </div>
            <div className="text-center">
                { quantity }
            </div>
            <div className="text-center">
                { price }$
            </div>
            <div className="text-center">
                { date }
            </div>
        </div>
    );
};

export default Order;