import React, { useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddProduct = ( props ) => {
    const setStatusMessage = props.setStatusMessage;
    const [ addProduct, setAddProduct ] = useState( {} );

    const handleBlur = ( e ) => {
        const newProduct = { ...addProduct };
        newProduct[ e.target.name ] = e.target.value;
        setAddProduct( newProduct );
    }
    const handleAddProduct = () => {
        fetch('https://warm-castle-19351.herokuapp.com/product', {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify( addProduct )
        })
        .then( res => res.json() )
        .then( res => res ? setStatusMessage( productAddSuccess ) : setStatusMessage( productAddError ) )
    }

    const productAddSuccess = () => {
        return <div onClick={ () => setStatusMessage('') } className="bg-green-50 p-3 mt-5 border-solid border-2 border-green-100 rounded-lg text-center text-green-600">
            <FontAwesomeIcon icon={ faCheck } /> Product added successfully!
        </div>
    }
    const productAddError = () => {
        return <div onClick={ () => setStatusMessage('') } className="bg-red-50 p-3 mt-5 border-solid border-2 border-red-100 rounded-lg text-center text-red-600">
            <FontAwesomeIcon icon={ faTimes } /> Error while adding new product.
        </div>
    }

    return (
    <div className="bg-gray-50 px-5 py-4 mt-7 mb-5 shadow-md">
        <h1 className="text-2xl text-gray-700 font-bold py-5 px-2">Add Product</h1>
        <div className="grid grid-cols-8 gap-4 bg-gray-50 px-2 justify-center">
            <input onBlur={ handleBlur } type="text" name="name" className="col-span-3 py-2 px-4 rounded-md bg-gray-100 focus:bg-indigo-100 text-gray-600 border-solid border-2 border-gray-200 font-medium" placeholder="Product name" />
            <input onBlur={ handleBlur } type="text" name="image" className="col-span-3 py-2 px-4 rounded-md bg-gray-100 focus:bg-indigo-100 text-gray-600 border-solid border-2 border-gray-200 font-medium" placeholder="Image url" />
            <input onBlur={ handleBlur } type="text" name="price" className="py-2 px-4 rounded-md bg-gray-100 focus:bg-indigo-100 text-gray-600 border-solid border-2 border-gray-200 font-medium" placeholder="Price" />
            <button onClick={ handleAddProduct } className="py-2 px-4 rounded-md bg-blue-600 hover:bg-indigo-600 text-white font-medium"> Add Product </button>
        </div>
    </div>
    );
};

export default AddProduct;