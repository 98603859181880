import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../App';

const Checkout = () => {
    const [ user, setUser ] = useContext(UserContext);
    const shoppingCart = JSON.parse( sessionStorage.getItem('cart') );
    const { productName, price } = shoppingCart;
    const history = useHistory();

    const handleCheckout = () => {
        const date = new Date().toLocaleDateString("en-BD");
        const order = {
            user: user.name,
            email: user.email,
            product: productName,
            price: price,
            quantity: 1,
            date: date
        }
        fetch('https://warm-castle-19351.herokuapp.com/order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( order )
        })
        .then(res => afterCheckout( res ))
    }
    const afterCheckout = ( res ) => {
        res ? alert('Order completed') : alert('Something went wrong')
        sessionStorage.removeItem('cart');
        history.push('/orders');
    }
    return (
        <main className="container max-auto">
            <h1 className="text-2xl text-gray-700 font-bold py-5 px-2 mt-8">Checkout</h1>
            <div className="grid grid-cols shadow-sm">
                <div className="grid grid-cols-4 text-gray-700 p-3 font-bold">
                    <div className="col-span-2">
                        Name
                    </div>
                    <div className="text-center">
                        Quantity
                    </div>
                    <div className="text-center">
                        Price
                    </div>
                </div>
                <div className="border border-gray-100"></div>
                <div className="grid grid-cols-4 text-gray-600 p-3 font-medium">
                    <div className="col-span-2">
                        { productName }
                    </div>
                    <div className="text-center">
                        1
                    </div>
                    <div className="text-center">
                        { price }$
                    </div>
                </div>
                <div className="border border-gray-100"></div>
                <div className="grid grid-cols-4 text-gray-700 bg-gray-50 p-3 font-bold">
                    <div className="col-span-2">
                    </div>
                    <div className="text-center">
                        Total
                    </div>
                    <div className="text-center">
                        { price }$
                    </div>
                </div>
                <div className="flex flex-row justify-end p-3">
                    <button onClick={handleCheckout} className="bg-blue-600 py-4 px-5 text-white hover:bg-indigo-600 rounded-lg font-medium">Place order</button>
                </div>
            </div>
        </main>
    );
};

export default Checkout;