
import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import AddProduct from './AddProduct';
import Product from './Product';

const Admin = () => {
    const [ products, setProducts ] = useState( [] );
    const [ statusMessage, setStatusMessage ] = useState('');

    useEffect( () => {
        fetch('https://warm-castle-19351.herokuapp.com/products')
        .then(res => res.json())
        .then(data => setProducts( data ))
    }, [ statusMessage ])

    return (
        <main className="container max-auto">
            { statusMessage }
            <AddProduct setStatusMessage={ setStatusMessage }></AddProduct>
            <h1 className="text-2xl text-gray-700 font-bold py-5 px-2">Manage Product</h1>
            <div className="grid grid-cols shadow-lg rounded-lg">
                <div className="grid grid-cols-4 bg-gray-100 text-gray-700 p-3 font-bold">
                    <div className="col-span-2">
                        Name
                    </div>
                    <div className="text-center">
                        Price
                    </div>
                    <div className="text-center">
                        Action
                    </div>
                </div>
                <div className="border border-gray-100"></div>
                {
                    products.length > 0 ? products.map( product => <Product setStatusMessage={ setStatusMessage } product={ product } key={ product._id }></Product> ) : <Spinner></Spinner>
                }
            </div>
        </main>
    );
};

export default Admin;