import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';


const Header = () => {
    const [ user, setUser ] = useContext(UserContext);

    const handleLogOut = () => {
        const newUser = {
            loggedIn: false
        }
        setUser( newUser );
    }
    const loginButton = () => {
        return <Link to="/login">
            <button className="py-2 px-4 my-3 rounded-md bg-blue-600 hover:bg-indigo-600 text-white font-medium">Login</button>
            </Link>
    }
    const logoutButton = () => {
        return <button onClick={ handleLogOut } className="py-2 px-4 my-3 rounded-md bg-blue-600 hover:bg-indigo-600 text-white font-medium">Logout</button>
    }
    return (
        <header className="container max-auto bg-gray-50 px-3">
            <nav className="flex flex-col md:flex-row justify-between">
                <span className="font-bold text-2xl py-5 text-blue-500"> PHONE STORE </span>
                <div className="flex flex-row md:justify-end">
                    <Link to="/" className="text-blue-600 font-medium my-5 mx-3 md:mx-7 hover:text-indigo-600">
                        Home
                    </Link>
                    <Link to="/orders" className="text-blue-600 font-medium my-5 mx-3 md:mx-7 hover:text-indigo-600">
                        Orders
                    </Link>
                    <Link to="/" className="text-blue-600 font-medium my-5 mx-3 md:mx-7 hover:text-indigo-600">
                        Deals
                    </Link>
                    <Link to="/admin" className="text-blue-600 font-medium my-5 mx-3 md:mx-7 hover:text-indigo-600">
                        Admin
                    </Link>
                    {
                        user.loggedIn ? logoutButton() : loginButton()
                    }
                </div>
            </nav>
        </header>
    );
};

export default Header;